import React, { useState } from 'react';
import "./categoryPage.css";
import SideMenu from "../../components/sideMenu/SideMenu";
import MostRead from './MostRead';
import MostCommented from './MostCommented';
import albumcover1 from "../photoAlbum/album1/album1cover.jpg";
import albumcover2 from "../photoAlbum/album2/album2cover.jpg";
import albumcover3 from "../photoAlbum/album3/album3cover.jpg";
import albumcover4 from "../photoAlbum/album4/album4cover.jpg";
import albumcover5 from "../photoAlbum/album5/album5cover.jpg";
import albumcover6 from "../photoAlbum/album6/album6cover.jpg";
import TitleBar from "../../components/titleBar/TitleBar";

const AlbumCategory = () => {

    const newsItems = [
        {
            id: 1,
            date: "18.12.2024",
            title: "PSM AWARDS’24 “Gelecek Burada”",
            image: albumcover6,
            link: "/photo-album-6",
        },
        {
            id: 1,
            date: "08.22.2023",
            title: "WSpark’23: Cumhuriyetin 100. yılında 100 kıvılcım onur listesinde",
            image: albumcover1,
            link: "/photo-album-1",
        },
        {
            id: 2,
            date: "08.23.2023",
            title: "PSM AWARDS WINNER 2023",
            image: albumcover2,
            link: "/photo-album-2",
        },
        {
            id: 3,
            date: "08.24.2023",
            title: "SMART-i AWARDS’23",
            image: albumcover3,
            link: "/photo-album-3",
        },
        {
            id: 4,
            date: "08.25.2023",
            title: "Kategori Toplu Fotoğraflar",
            image: albumcover4,
            link: "/photo-album-4",
        },
        {
            id: 5,
            date: "08.26.2023",
            title: "PSM AWARDS’22 Panel: Dijital Tsunami",
            image: albumcover5,
            link: "/photo-album-5",
        }
    ];

    const [visibleItems, setVisibleItems] = useState(18);

    const showMoreItems = () => {
        setVisibleItems(prevVisibleItems => prevVisibleItems + 9); // 9 items per load, adjust as needed
    };


    return (
        <div className='category-page-container'>

            <div className='category-page-top-container md:grid grid-cols-11 md:px-60'>

                <div className='category-page-top-left-container col-span-7 md:pr-[30px]'>
                <div className="mb-4 md:hidden"><TitleBar title="Foto Galeri" /></div>
                    <div className="category-news-grid-container">
                        <div className="category-news-grid">
                            {newsItems.slice(0, visibleItems).map((item) => (
                                <a href={item.link} key={item.id} className="category-news-item">
                                    <img src={item.image} alt={item.title} />
                                    <div className="category-news-content">
                                        <h3 className="title">{item.title}</h3>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className='text-center'>
                        {visibleItems < newsItems.length && (
                            <button onClick={showMoreItems} className="load-more-button category-page-load-more-button ">
                                Daha Fazla Albüm Yükle
                            </button>
                        )}
                    </div>



                </div>

                <div className='category-page-top-right-container col-span-4 md:pl-[30px]'>

                    <SideMenu />

                </div>

            </div>


        </div>
    )
}

export default AlbumCategory