import React, { useEffect, useRef } from "react";

const AdContainer = ({ ads, currentAdIndex, handleAdClick }) => {
  const adRef = useRef(null);

  useEffect(() => {
    if (ads.length === 0 || !ads[currentAdIndex]) return;

    const currentAd = ads[currentAdIndex];
    adRef.current.innerHTML = ""; // Clear previous content

    if (
      currentAd.imageURL.includes("doubleclick.net") ||
      currentAd.imageURL.includes("sadbundle")
    ) {
      const iframe = document.createElement("iframe");
      iframe.src = currentAd.imageURL;
      iframe.style = "width: 100%; height: 100%; border: 0; vertical-align: bottom; max-height: 70px; object-fit: contain;";
      iframe.frameBorder = "0";
      iframe.scrolling = "no";
      iframe.allowFullscreen = true;
      adRef.current.appendChild(iframe);
    } else {
      const img = document.createElement("img");
      img.src = currentAd.imageURL;
      img.alt = currentAd.title;
      img.style = "width: 100%; height: 100%; object-fit: contain; cursor: pointer; max-height: 70px; max-width: 100%;";
      img.onclick = () => handleAdClick(currentAd);
      adRef.current.appendChild(img);
    }
  }, [ads, currentAdIndex, handleAdClick]);

  return (
    <div
      className="advertisement"
      style={{
        width: "100%",
        height: "auto",
        maxHeight: "70px", // Max height to avoid overflow
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      ref={adRef}
    >
      <div ref={adRef} style={{ width: "100%", height: "100%" }}></div>
    </div>
  );
};

export default AdContainer;
