import React, { useRef, useEffect } from "react";
import { useParams, Link } from 'react-router-dom'; // Import useParams
import "./VideoContentPage.css";
import CommentSection from "../../components/comments/CommentSection";
import { FaInstagram, FaLinkedin, FaWhatsapp, FaFacebook, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";


const VideoContentPage = () => {

  const { id } = useParams(); // Get the video id from the URL
  const videoItems = [
    {
        id: 1,
        link: "https://www.youtube.com/embed/gIgp3q_G954?si=B9-HcQMk99oC9HzJ",
        date: "08.22.2023",
        title: "PSM GÜNDEM TOPLANTISI: Açık Bankacılık",
        image: "https://www.psmmag.com/wp-content/uploads/2024/05/a0893c6c-86c6-4e49-9eef-98c3f0f93fe4-740x420.jpeg",
    },
    {
        id: 2,
        link: "https://www.youtube.com/embed/4WqMi17KWAA",
        date: "08.23.2023",
        title: "Fintek’lerin Yeni Çağı: AÇIK BANKACILIK",
        image: "https://www.psmmag.com/wp-content/uploads/2024/05/6d9844f1-bc3d-4495-8c04-4ac14b07d906-740x420.jpeg",
    },
    {
        id: 3,
        link: "https://www.psmmag.com/wp-content/uploads/2024/04/WhatsApp-Video-2024-04-02-at-15.20.43.mp4?_=2",
        date: "08.24.2023",
        title: "Kadın liderler buluştu, W-Level kız çocukları için eğitim desteğine dönüştü",
        image: "https://www.psmmag.com/wp-content/uploads/2024/04/WLevel_20.03.2024-740x420.jpg",
    },
    {
        id: 4,
        link: "https://www.youtube.com/embed/Hou9vPOOEl8",
        date: "08.25.2023",
        title: "WSpark’23 PANEL: Yeni yüzyılda kadın ve teknoloji",
        image: "https://www.psmmag.com/wp-content/uploads/2024/01/WSpark-Panel.jpg",
    },
    {
        id: 5,
        link: "https://www.youtube.com/embed/3oDwmmLLlPY",
        date: "08.26.2023",
        title: "PSM AWARDS 2023 | PANEL: Cesur Yeni Dünya",
        image: "https://www.psmmag.com/wp-content/uploads/2023/12/Ekran-Resmi-2023-12-25-22.55.46-740x420.png",
    },
    {
        id: 6,
        link: "https://www.youtube.com/embed/FufcFPAdPPs",
        date: "08.27.2023",
        title: "PSM AWARDS WINNER 2023",
        image: "https://www.psmmag.com/wp-content/uploads/2023/12/WhatsApp-Image-2023-12-20-at-10.36.39-740x420.jpeg",
    },
    {
        id: 7,
        link: "https://www.youtube.com/embed/xGsAZAoXPcM",
        date: "08.28.2023",
        title: "Smart’i Awards 26 Eylül 2023 Ödül Töreni",
        image: "https://www.psmmag.com/wp-content/uploads/2023/09/WhatsApp-Image-2023-09-30-at-20.57.26-740x420.jpeg",
    },
    {
        id: 8,
        link: "https://www.youtube.com/embed/GcLPU4B2cGM",
        date: "08.29.2023",
        title: "Smart-i Awards’23 Panel",
        image: "https://www.psmmag.com/wp-content/uploads/2023/09/WhatsApp-Image-2023-09-30-at-20.56.54-740x420.jpeg",
    },
    {
        id: 9,
        link: "https://www.youtube.com/embed/DWDsav5StV8",
        date: "08.30.2023",
        title: "SMART-i AWARDS’23",
        image: "https://www.psmmag.com/wp-content/uploads/2023/09/WhatsApp-Image-2023-09-27-at-20.47.14-740x420.jpeg",
    },
    {
        id: 10,
        link: "https://www.youtube.com/embed/4cczNDnebp4",
        date: "08.31.2023",
        title: "( canlı yayın ) Finansal devrimin yeni adı: Açık bankacılık",
        image: "https://www.psmmag.com/wp-content/uploads/2023/05/Ekran-Resmi-2023-05-25-10.57.18-740x420.png",
    },
    {
        id: 11,
        link: "https://www.youtube.com/embed/PlBOxTyfT1s",
        date: "09.01.2023",
        title: "Papara Voice Card ile dünyada bir ilk!",
        image: "https://www.psmmag.com/wp-content/uploads/2023/01/papara2-740x420.jpg",
    },
    {
        id: 12,
        link: "https://psmawards.com/Psm-Awards-Winner-22.mp4",
        date: "09.02.2023",
        title: "Dijital tsunaminin öncüleri ödüllerine kavuştu",
        image: "https://www.psmmag.com/wp-content/uploads/2022/12/PSM-AWARDS-2022-1.jpg",
    },
    {
        id: 13,
        link: "https://www.youtube.com/embed/n2UE7GFfE28?si=rTZcGAklS4IQfOQp",
        date: "09.03.2023",
        title: "PSM AWARDS’22 Ödül Töreni",
        image: "https://www.psmmag.com/wp-content/uploads/2022/12/WhatsApp-Image-2022-12-25-at-14.07.38-740x420.jpeg",
    },
    {
        id: 14,
        link: "https://www.youtube.com/embed/qcUe0G5_c_M",
        date: "09.04.2023",
        title: "PSM AWARDS’22 Panel: Dijital Tsunami",
        image: "https://www.psmmag.com/wp-content/uploads/2022/12/Ekran-Resmi-2022-12-25-13.34.43-740x420.png",
    },
    {
        id: 15,
        link: "https://www.psmmag.com/Panel-3-1.m4v",
        date: "09.05.2023",
        title: "PANEL: Dijital tsunami 7 öngörü",
        image: "https://www.psmmag.com/wp-content/uploads/2022/12/jpg_20221213_145117_0000.jpg",
    },
    {
        id: 16,
        link: "https://www.youtube.com/embed/zIQfffvJVZk",
        date: "09.06.2023",
        title: "PSM AWARDS’21",
        image: "https://www.psmmag.com/wp-content/uploads/2022/12/psm_awards2021-740x420.jpeg",
    },
    {
        id: 17,
        link: "https://www.psmmag.com/Psm-odul-toreni-davet-yayin.mp4?autoplay=1",
        date: "09.07.2023",
        title: "13 kategori, 385 proje, 107 finalist, 7 öngörü",
        image: "https://www.psmmag.com/wp-content/uploads/2022/12/psmawards22-740x420.png",
    },
];


  const video = videoItems.find((item) => item.id === parseInt(id)); // Find the video by id

  const currentIndex = videoItems.findIndex((item) => item.id === parseInt(id));
  const prevVideo = videoItems[currentIndex - 1];
  const nextVideo = videoItems[currentIndex + 1];

  const shareUrl = window.location.href;

  const shareOnFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const shareOnTwitter = () => {
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const shareOnLinkedIn = () => {
    const url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const shareOnWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  if (!video) return <div>Video bulunamadı</div>; // Eğer video bulunamazsa hata mesajı göster

  

  return (
    <div className="video-content-page-container">
      <div className="page-link-container md:px-60 items-center flex flex-wrap text-left text-base mt-6 font-medium">
        <a href="#" className="hover:underline">PSM Magazine</a>
        <span class="material-symbols-outlined">
chevron_right
</span>
        <a href="/video-galeri" className="hover:underline">Video Galeri</a>
        <span class="material-symbols-outlined">
chevron_right
</span>
        <a href="#" className="hover:underline">{video.title}</a>
      </div>

      <div className="video-content-page-header md:px-60 py-6">
        <h1 className="text-4xl font-bold mb-3">{video.title}</h1>
        <img src={video.image} alt={video.title} />
        <div className="flex items-center mt-2 text-gray-600">
          <div>
            {/* <p className="text-xs">Yorum <span class="material-symbols-outlined text-sm">
chat_bubble
</span>
            • Görüntülenme <i className="fa-solid fa-eye"></i>
            </p> */}
          </div>
        </div>
      </div>

      <div className="video-content-container md:px-60">
        <div className="video-content-left-container md:pr-[30px]">
          <div className="video-content">
          <iframe
              
              width="100%"
              height="500"
              src={video.link}
              title={video.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>

          <CommentSection />

          <div className="share-card-container text-white mt-8 mb-4">
            <div className="h-14 bg-[#360306] pl-6 flex items-center">
              <span class="material-symbols-outlined mr-4">
share
</span>
              <span className="font-bold mr-6">PAYLAŞ</span>

              <div className="share-link-container text-lg flex gap-2">
                <a onClick={shareOnFacebook} className="hover:bg-[#0867FF] hover:border-[#0867FF] cursor-pointer">
                  <FaFacebook />
                </a>
                <a onClick={shareOnTwitter} className="hover:bg-[#50ABF1] hover:border-[#50ABF1] cursor-pointer">
                  <FaXTwitter />
                </a>
                <a onClick={shareOnLinkedIn} className="hover:bg-[#0274B3] hover:border-[#0274B3] cursor-pointer">
                  <FaLinkedin />
                </a>
                <a onClick={shareOnWhatsApp} className="hover:bg-[#26CA43] hover:border-[#26CA43] cursor-pointer">
                  <FaWhatsapp />
                </a>
              </div>
            </div>

            <div className="h-24 bg-[#C90913] px-6 py-3 grid grid-cols-2">
              <div className="text-left pr-6 border-r border-neutral-100">
                {prevVideo ? (
                  <Link to={`/video/${prevVideo.id}`}>
                    <span class="material-symbols-outlined">
arrow_back
</span>
                    <span className="next-previous-content md:mx-4 text-neutral-200">
                      Önceki Video
                    </span>
                    <h4 className="text-sm mt-2 truncate">{prevVideo.title}</h4>
                  </Link>
                ) : (
                  <span className="text-neutral-100">Önceki Video Yok</span>
                )}
              </div>
              <div className="text-right pl-6">
                {nextVideo ? (
                  <Link to={`/video/${nextVideo.id}`}>
                    <span className="next-previous-content md:mx-4 text-neutral-200">
                      Sonraki Video
                    </span>
                    <span class="material-symbols-outlined">
arrow_forward
</span>
                    <h4 className="text-sm mt-2 truncate">{nextVideo.title}</h4>
                  </Link>
                ) : (
                  <span className="text-neutral-100">Sonraki Video Yok</span>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="video-content-right-container col-span-4 md:pl-[30px]">
          <SideMenu />
        </div> */}
      </div>
    </div>
  );
};

export default VideoContentPage;
