import React, { useState, useEffect } from 'react';
import "./adsmobile.css";

const AdContainer = ({ ad, handleAdClick }) => {
  const adRef = React.useRef(null);
  const [adHeight, setAdHeight] = useState('auto');

  React.useEffect(() => {
    adRef.current.innerHTML = ''; // Clear previous content

    if (ad.imageURL.includes('doubleclick.net') || ad.imageURL.includes('sadbundle')) {
      const iframe = document.createElement('iframe');
      iframe.src = ad.imageURL;
      iframe.style = 'width: 100%; height: 100%; border: 0;';
      iframe.frameBorder = '0';
      iframe.scrolling = 'no';
      iframe.allowFullscreen = true;
      iframe.onload = () => {
        setAdHeight(`${iframe.contentWindow.document.body.scrollHeight}px`);
      };
      adRef.current.appendChild(iframe);
    } else {
      const img = document.createElement('img');
      img.src = ad.imageURL;
      img.alt = ``;
      img.style = 'width: 100%; height: auto; object-fit: contain;';
      img.onload = () => {
        setAdHeight(`${img.height}px`);
      };
      adRef.current.appendChild(img);
    }
  }, [ad]);

  return (
    <div className="slide" style={{ height: adHeight }}>
      <a
        href={ad.link || "#"}
        className="slideImg"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => handleAdClick(ad._id)}
      >
        <div ref={adRef} style={{ width: '100%', height: '100%' }}></div>
      </a>
    </div>
  );
};

const AdsMobile = () => {
  const [slidesData, setSlidesData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    fetch("https://api.psmmag.com/api/ads")
      .then(response => response.json())
      .then(data => {
        const filteredSlides = data.filter(
          ad =>
            (ad.title.includes("MOBİL YATAY-1") || ad.title.includes("MOBİL YATAY-2")) &&
            ad.isActive === true
        );
        setSlidesData(filteredSlides);
      })
      .catch(error => {
        console.error("Error fetching advertisement data:", error);
      });
  }, []);

  useEffect(() => {
    if (slidesData.length === 0) return;
    const interval = setInterval(() => {
      setCurrentSlide(prevSlide => (prevSlide + 1) % slidesData.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [slidesData]);

  const handleAdClick = async (adId) => {
    try {
      await fetch(`https://api.psmmag.com/api/ads/${adId}/hit`, {
        method: "POST",
      });
    } catch (error) {
      console.error("Error incrementing hit count:", error);
    }
  };

  if (slidesData.length === 0) {
    return null; // Don't render anything if there are no active ads
  }

  return (
    <div className='mobile-ads-container'>
      <div className="slider">
        <div
          className="slider-container"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {slidesData.map((slide) => (
            <AdContainer key={slide._id} ad={slide} handleAdClick={handleAdClick} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdsMobile;